import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, CustomInput } from "reactstrap";
import {
  CloseButton,
  DeleteModal,
  DoseCell,
  FrequencyCell,
  MedicationCell,
  NoEditModal,
  SimpleTable
} from "../_components";
import { isPRN, permissionsEnum } from "../_helpers";
import { sigCodes } from "../_helpers/Frequency";
import { UseMobileMediaQuery } from "../_helpers/media-queries";
import { prnReasons } from "../_helpers/prnreasons";
import { useUserContext } from "../context/userContext";
import { AddMedications } from "../home/AddMedications";
import { SuccessModal } from "../home/SuccessModal";
import {
  CustomInstructionText,
  InstructionText,
  RouteText
} from "../home/formComponents";
import { ReactComponent as Illustration } from "../images/illustration-screen-bottle-x-phone.svg";
import { ReactComponent as Sync } from "../images/sync_FILL.svg";
import PropTypes from "prop-types";

import { administrationRoutes } from "../_helpers/administrativeRoute";
import { quantityUnitsEnum } from "../_helpers/quantityUnits";

const InstructionsCell = ({ row }) => {
  const findItemByValue = (nestedArray, searchValue, val) => {
    let foundItem = null;

    nestedArray.some((group) => {
      if (searchValue >= 0 || typeof searchValue === "string")
        // Check each option in the group
        foundItem = group.options.find((option) => option[val] === searchValue);

      // If the item is found, stop searching
      return foundItem !== undefined;
    });

    return foundItem;
  };
  const { medicationSchedule, medicationStrength, isSmartAppMedication } = row;
  const {
    instructionText,
    prnReason,
    sigCode,
    prnReasonOther,
    medicationScheduleTimes,
    doseQuantity,
    daysOn,
    daysOff,
    totalNumberOfCycles,
    quantityUnit,
    quantityUnitId
  } = medicationSchedule;
  let dq = doseQuantity;
  let qu =
    quantityUnit ||
    quantityUnitsEnum.find((item) => item.value === quantityUnitId)?.abbr;

  const { routeId } = medicationStrength;

  if (
    !doseQuantity &&
    medicationScheduleTimes.length &&
    medicationScheduleTimes[0].doseQuantity
  ) {
    dq = medicationScheduleTimes[0].doseQuantity;
  }
  const findRoute =
    // search route enum to find the route description by value
    administrationRoutes.find((item) => item.value === routeId);

  return (
    <div>
      {/* make bullet point list */}

      {((dq || findRoute?.instructionText || sigCode) &&
        !isSmartAppMedication) ||
      instructionText ? (
        <ul className="pl-5 mb-0">
          {!isSmartAppMedication && (
            <>
              {(dq || sigCode) && (
                <li>
                  <InstructionText
                    dose={dq}
                    doseType={{ label: qu }}
                    frequency={
                      sigCode === "Cycle"
                        ? { value: "Cycle", label: "Cycle" }
                        : findItemByValue(sigCodes, sigCode, "label")
                    }
                    prn={isPRN(medicationSchedule)}
                    prnReason={
                      findItemByValue(prnReasons, prnReason, "value") || {
                        value: prnReasonOther,
                        label: prnReasonOther
                      }
                    }
                    daysOn={daysOn}
                    daysOff={daysOff}
                    totalNumberOfCycles={totalNumberOfCycles}
                    instructionText={instructionText}
                    medScheduleTimes={medicationScheduleTimes}
                  />
                </li>
              )}
              {findRoute?.instructionText && (
                <li>
                  <RouteText route={routeId} />
                </li>
              )}
            </>
          )}
          {instructionText && (
            <li>
              <CustomInstructionText instructionText={instructionText} />
            </li>
          )}
        </ul>
      ) : (
        <>--</>
      )}
    </div>
  );
};
InstructionsCell.propTypes = {
  row: PropTypes.object
};

const InstructionsHeader = ({ isMobileWidth, user }) => {
  let text;
  if (user?.isSmartSession) {
    text = "Patient Instructions from EHR";
  } else if (isMobileWidth) {
    text = "Instrs.";
  } else {
    text = "Instructions";
  }
  return <>{text}</>;
};
InstructionsHeader.propTypes = {
  isMobileWidth: PropTypes.bool,
  user: PropTypes.object
};

const ActionsCell = ({
  cellprops,
  onEdit,
  editDisabled,
  toggleNoEditModal
}) => {
  return (
    <button
      className="btn btn-link p-0 text-nowrap"
      onClick={() => {
        if (editDisabled) {
          toggleNoEditModal();
        } else onEdit(cellprops);
      }}
    >
      Edit
    </button>
  );
};
ActionsCell.propTypes = {
  cellprops: PropTypes.object,
  onEdit: PropTypes.func,
  editDisabled: PropTypes.bool,
  toggleNoEditModal: PropTypes.func
};

const MedTable = ({
  meds,
  setMeds,
  setFormEdited,
  needsLocalStorage,
  inviteVersion,
  loading,
  smartVersion,
  syncMedications
}) => {
  const isMobileWidth = UseMobileMediaQuery();
  const { userState } = useUserContext();
  const { user, userPermissions } = userState;
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [syncModal, setSyncModal] = useState(false);
  const [modalNumber, setModalNumber] = useState(1);
  const [medicationName, setMedName] = useState("");
  const [name, setName] = useState("");
  const [rowId, setRowId] = useState(null);
  const [disableFields, setDisableFields] = useState(false);
  const [selectedMed, setSelectedMed] = useState({});
  const [noEditModal, setNoEditModal] = useState(false);
  const [editDisabled, setEditDisabled] = useState(false);

  const toggleDelete = useCallback(() => {
    setDeleteModal(!deleteModal);
  }, [deleteModal]);

  const toggleAdd = useCallback(() => {
    if (addModal) setModalNumber(1);
    setAddModal(!addModal);
  }, [addModal]);

  const toggleNoEditModal = useCallback(() => {
    setNoEditModal(!noEditModal);
  }, [noEditModal]);

  const toggleSyncModal = useCallback(() => {
    setSyncModal(!syncModal);
  }, [syncModal]);

  const goBack = () => {
    setModalNumber(1);
  };

  useEffect(() => {
    if (
      userPermissions?.indexOf(permissionsEnum.SmartMedicationsUpdateOnInvite) <
        0 &&
      user?.isSmartSession
    ) {
      setEditDisabled(true);
    }
  }, [user?.isSmartSession, userPermissions]);

  useEffect(() => {
    if (needsLocalStorage) {
      localStorage.setItem("addMedsList", JSON.stringify(meds));
    }
  }, [meds, needsLocalStorage, user]);

  const handleSwitchChange = React.useCallback(
    (e, row) => {
      if (setFormEdited) setFormEdited(true);
      const { checked } = e.target;
      if (!checked) {
        setMeds((prev) => {
          // find med with the same id and change the status to 2
          // return the array
          const med = prev.find((item) => {
            if (row.original.tempId) {
              return item.tempId === row.original.tempId;
            } else return item.id === row.original.id;
          });
          med.medicationApprovalStatus = 2;

          return [...prev];
        });
      }
      if (checked) {
        setMeds((prev) => {
          // find med with the same id and change the status to 1
          // return the array
          const med = prev.find((item) => {
            if (row.original.tempId) {
              return item.tempId === row.original.tempId;
            } else return item.id === row.original.id;
          });
          med.medicationApprovalStatus = 1;
          return [...prev];
        });
      }
    },
    [setFormEdited, setMeds]
  );

  // const editMedForTable = React.useCallback((med) => {
  //   if (med.medicationSchedule) {
  //     const {
  //       medicationSchedule,
  //       medicationName,
  //       medicationStrength,
  //       drugDetailId
  //     } = med;
  //     const {
  //       scheduleType,
  //       instructionText,
  //       medicationScheduleTimes,
  //       scheduleQualifier
  //     } = medicationSchedule;
  //     let idx = separateInstructionText(instructionText);
  //     let freq = schedType(scheduleType, scheduleQualifier);
  //     return {
  //       medicationName: medicationName,

  //       medicationSchedule: freq,
  //       medicationStrength: medicationStrength,
  //       doses: scheduleType === 1 ? [] : medicationScheduleTimes,
  //       // med.selectedPlan?.medicationPlanSteps
  //       //   ? []
  //       //   :

  //       customInstructions: instructionText?.slice(idx + 1),
  //       instructions: instructionText?.slice(0, idx),
  //       drugDetailId: drugDetailId,
  //       scheduleType: scheduleType,
  //       id: med.id,
  //       medicationId: med.medicationId || med.tempId,
  //       tempId: med.tempId,
  //       medicationApprovalStatus: med.medicationApprovalStatus,
  //       isSmartAppMedication: med.isSmartAppMedication
  //     };
  //   }
  //   // else {
  //   //   const { medicationPlanSteps, medicationPlanName } = med;

  //   //   return {
  //   //     medicationName: medicationPlanSteps[0].medications[0].medicationName,
  //   //     medicationSchedule: "Medication Plan",
  //   //     medicationStrength:
  //   //       medicationPlanSteps[0].medications[0].medicationStrength,
  //   //     doses: [],
  //   //     customInstructions: "",
  //   //     instructions: "",
  //   //     drugDetailId: medicationPlanSteps[0].medications[0].drugDetailId,
  //   //     scheduleType: medicationPlanName ? 8 : 7
  //   //   };
  //   // }
  // }, []);

  const onEdit = useCallback(
    (props) => {
      let selected = meds.find((item) => {
        // if (item.medicationPlanSteps) {
        //   item.drugDetailId =
        //     item.medicationPlanSteps[0].medications[0].drugDetailId;
        //   item.medicationName =
        //     item.medicationPlanSteps[0].medications[0].medicationName;
        //   item.medicationStrength =
        //     item.medicationPlanSteps[0].medications[0].medicationStrength;
        // }
        if (props.row.original.id) return item.id === props.row.original.id;
        else if (props.row.original.tempId)
          return item.tempId === props.row.original.tempId;
        return null;
      });
      setName(selected.medicationName);
      setRowId(props.row.index);
      setSelectedMed(selected);
      if (user?.isSmartSession) {
        setDisableFields(true);
      } else {
        setDisableFields(false);
      }
      toggleAdd();
    },
    [meds, toggleAdd, user]
  );

  const initialState = {
    data: meds
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => <>{isMobileWidth ? "Med" : "Medication"}</>,
        accessor: "medicationName",
        Cell: (props) => {
          return <MedicationCell row={props.row} value={props.value} />;
        }
      },
      {
        Header: "Dose",
        accessor: "medicationSchedule",
        Cell: (props) => {
          return (
            <DoseCell
              medicationSchedule={props.value}
              row={props.row.original}
              setMeds={setMeds}
              setFormEdited={setFormEdited}
              editDisabled={editDisabled}
            />
          );
        }
      },

      {
        Header: () => <>{isMobileWidth ? "Freq." : "Frequency"}</>,
        accessor: "medicationSchedule.sigCode",
        Cell: (props) => {
          return (
            <FrequencyCell
              value={props.value}
              row={props.row.original}
              setMeds={setMeds}
              setFormEdited={setFormEdited}
              editDisabled={editDisabled}
            />
          );
        }
      },

      {
        Header: () => (
          <InstructionsHeader isMobileWidth={isMobileWidth} user={user} />
        ),
        id: "instructions",
        accessor: (row) => {
          return <InstructionsCell row={row} />;
        }
      },
      {
        Header: "Include?",
        accessor: "medicationApprovalStatus",
        Cell: (props) => {
          return (
            <div className="d-flex justify-content-center">
              <CustomInput
                defaultChecked={props.value !== 2}
                type="checkbox"
                data-testid={`toggle-${props.row.id}`}
                name={`toggle-${props.row.id}`}
                id={`toggle-${props.row.id}`}
                onChange={(e) => handleSwitchChange(e, props.row)}
                className="blue-checkmark"
              />
            </div>
          );
        }
      },
      {
        Header: "",
        accessor: "actions",
        Cell: (props) => {
          return (
            <ActionsCell
              cellprops={props}
              onEdit={onEdit}
              editDisabled={editDisabled}
              toggleNoEditModal={toggleNoEditModal}
            />
          );
        }
      }
    ],
    [
      editDisabled,
      handleSwitchChange,
      isMobileWidth,
      onEdit,
      setFormEdited,
      setMeds,
      toggleNoEditModal,
      user
    ]
  );

  return (
    <>
      {/* Add Med button moved outside of the table for smart only, but has to be inside for portal */}
      {user?.isSmartSession && !editDisabled && (
        <div className="d-flex align-items-center bg-white justify-content-between py-2 px-5">
          <h6 className="text-primary primary-underline">Medication List</h6>
          <div className="">
            <button
              type="button"
              className="btn btn-primary h-50"
              onClick={() => {
                setDisableFields(false);
                setSelectedMed({});
                setRowId(null);
                toggleAdd();
                // const btn = document.getElementById("add-medication");
                // btn.blur();
              }}
              id="add-medication"
            >
              Add Med
            </button>
          </div>
        </div>
      )}
      <div className="card bg-white px-0">
        <div className="card-body pt-0 ie-no-padding px-md-4 px-lg-6 med-table-style">
          <div id="med-table-container" className="">
            {syncMedications && (
              <div className="d-flex">
                <button
                  className="btn btn-link ml-auto mr-4 mb-neg-2-5rem zIndex-10000"
                  onClick={toggleSyncModal}
                  type="button"
                  data-testid="sync-btn"
                >
                  <Sync
                    className="glyph-gray-fill"
                    alt="sync"
                    aria-label="sync"
                  />
                </button>
              </div>
            )}
            <div>
              <SimpleTable
                data={meds.filter((med) => !med.status)}
                columns={
                  smartVersion && !editDisabled
                    ? columns
                    : columns.filter((col) => col.Header !== "Include?")
                }
                initialState={initialState}
                emptyMsg={!inviteVersion && "This patient has no Imported Meds"}
                loading={loading}
                emptyImg={
                  !inviteVersion && (
                    <Illustration
                      className={classnames(
                        { "w-14rem": isMobileWidth },
                        "image-fluid glyph-grooveblue mx-md-7 mb-6 mt-5 overflow-hidden"
                      )}
                      alt="Empty State"
                      aria-label="empty-state-illustration"
                    />
                  )
                }
                id="med-table"
              />
              {/* just a way to determine if it's in edit medications modal and not duplicating the button */}
              {!user?.isSmartSession && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="text-center btn btn-link"
                    onClick={() => {
                      setDisableFields(false);
                      setSelectedMed({});
                      setRowId(null);
                      toggleAdd();
                      // const btn = document.getElementById("add-medication");
                      // btn.blur();
                    }}
                    id="add-medication"
                  >
                    + Add New Med
                  </button>
                </div>
              )}
            </div>
            <DeleteModal
              name={name}
              toggleAdd={toggleAdd}
              deleteModal={deleteModal}
              toggleDelete={toggleDelete}
              selectedMed={selectedMed}
              setMeds={setMeds}
              rowId={rowId}
              setFormEdited={setFormEdited}
              isSideBarRoute={inviteVersion}
            />

            <Modal
              isOpen={addModal}
              toggle={toggleAdd}
              className={classnames(
                {
                  "sidebar-modal": inviteVersion && user && !user.isSmartSession
                },
                { "scroll-modal": modalNumber === 1 },
                "center-modal  add-meds-modal"
              )}
            >
              <ModalHeader
                toggle={toggleAdd}
                close={<CloseButton toggle={toggleAdd} />}
              >
                {selectedMed.medicationName
                  ? `Edit ${selectedMed.medicationName}`
                  : "Add Medications"}
              </ModalHeader>
              <ModalBody>
                {modalNumber === 1 && addModal && (
                  <AddMedications
                    setModalNumber={setModalNumber}
                    setMedName={setMedName}
                    setMeds={setMeds}
                    meds={meds}
                    setFormEdited={setFormEdited}
                    selectedMed={selectedMed}
                    toggleDelete={toggleDelete}
                    modalNumber={modalNumber}
                    goBack={goBack}
                    name={name}
                    rowId={rowId}
                    disableFields={disableFields}
                  />
                )}
                {modalNumber > 1 && addModal && (
                  <SuccessModal
                    setRowId={setRowId}
                    medName={medicationName}
                    editVersion={selectedMed.medicationName ? true : false}
                    setSelectedMed={setSelectedMed}
                    goBack={goBack}
                    toggle={toggleAdd}
                  />
                )}
              </ModalBody>
            </Modal>
            {/* sync modal */}
            <Modal
              isOpen={syncModal}
              toggle={toggleSyncModal}
              className="center-modal"
            >
              <ModalHeader
                toggle={toggleSyncModal}
                close={<CloseButton toggle={toggleSyncModal} />}
              >
                Sync Medications from EHR
              </ModalHeader>
              <ModalBody>
                <div className="">
                  <p className="mb-4">
                    Are you sure you want to sync this patient's medications?
                    This will override any changes you have made to the
                    medication list.
                  </p>
                  <div className="d-flex flex-md-row flex-column mt-3">
                    <button
                      onClick={() => {
                        syncMedications();
                        setTimeout(() => {
                          toggleSyncModal();
                        }, 500);
                      }}
                      className="btn btn-primary mr-md-4 mr-0 mb-md-0 mb-3"
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Sync
                    </button>
                    <button
                      onClick={toggleSyncModal}
                      className="btn btn-outline-secondary"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <NoEditModal isOpen={noEditModal} toggle={toggleNoEditModal} />
          </div>
        </div>
      </div>
    </>
  );
};
MedTable.propTypes = {
  meds: PropTypes.array,
  setMeds: PropTypes.func,
  setFormEdited: PropTypes.func,
  needsLocalStorage: PropTypes.bool,
  inviteVersion: PropTypes.bool,
  loading: PropTypes.bool,
  smartVersion: PropTypes.bool,
  syncMedications: PropTypes.func
};

export { MedTable };
