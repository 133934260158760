import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { year } from "../_helpers";
import { useAuthContext } from "../context/auth0Context";
import { SmartContext } from "../context/smartContext";
import { ReactComponent as Exclamation } from "../images/circle-exclaimation.svg";
import { AuthenticationCheck } from "./AuthenticationCheck";

const AccessDenied = (props) => {
  const { shouldUseAuth0 } = useAuthContext();
  const { patientId, patientInviteStatus, isMPages } = useContext(SmartContext);
  const [link, setLink] = useState("");

  useEffect(() => {
    if (patientId) {
      if (
        patientInviteStatus === 0 ||
        patientInviteStatus === 4 ||
        patientInviteStatus === 5
      ) {
        setLink(`/smart/invite/${patientId}`);
      } else if (patientInviteStatus === 1 || patientInviteStatus === 3) {
        setLink(`/smart/manage-invitations/manage/${patientId}`);
      } else if (patientInviteStatus >= 6) {
        setLink(`/smart/400`);
      } else {
        setLink(`/smart/patients/${patientId}`);
      }
    } else setLink("/smart/400");
  }, [patientId, patientInviteStatus]);
  return (
    <div
      id="access-denied"
      className="col-6 offset-3 col-lg-4 offset-lg-4 d-flex flex-column justify-content-between"
    >
      {" "}
      <div className="d-flex">
        <Exclamation
          className="mr-2 glyph-gray h-2rem w-4-5rem"
          aria-label="exclamation"
        />
        <div>
          <h4>Access Denied/Forbidden</h4>
          <h6 className="mb-4">Error 403: Forbidden</h6>
          <p>
            You do not have the permission to access this page. For more details
            contact your system administrator.
          </p>
          {shouldUseAuth0 ? (
            <AuthenticationCheck />
          ) : (
            !isMPages &&
            link && (
              <NavLink to={link} className="btn btn-link pl-0">
                Back to Home
              </NavLink>
            )
          )}
        </div>
      </div>
      <div className="text-secondary text-center">
        <Link to="/support" className="text-primary">
          Contact Support
        </Link>
        <div className="pt-4">© {year} EveryDose. All rights reserved. </div>
      </div>
    </div>
  );
};

export { AccessDenied };
