import { fetchWrapper } from "../_helpers/index";
export const patientInviteService = {
  invite,
  resend,
  withdraw,
  resendBulk,
  withdrawBulk,
  editInvite,
  getInvites,
  getInvite,
  getInvitationHistory,
  getInviteStatus,
  smartInvite,
  getSmartMedications,
  getEhrPatient,
  getEhrMedications,
  ehrInvite,
  addPatients,
  removePatients,
  mergePatient,
  getSignUpDocument,
  getPatientSignUpDocument,
  emailInvite,
  smsInvite,
  createManageInvitationReport
  // createReport
};

const baseUrl = "/api/patients";

function invite(fields) {
  if (!fields.phoneNumber) fields.phoneNumber = null;
  if (!fields.email) fields.email = null;
  return fetchWrapper.post(`${baseUrl}/invitations/create`, fields);
}

function ehrInvite(fields, id, integrationId) {
  if (!fields.phoneNumber) fields.phoneNumber = null;
  if (!fields.email) fields.email = null;
  return fetchWrapper.post(
    `${baseUrl}/invitations/ehr/${integrationId}/${id}/create`,
    fields
  );
}

function smartInvite(fields, id) {
  if (!fields.phoneNumber) fields.phoneNumber = null;
  if (!fields.email) fields.email = null;
  return fetchWrapper.post(`${baseUrl}/invitations/smart/${id}/create`, fields);
}

function getSmartMedications(id) {
  return fetchWrapper.get(`${baseUrl}/invitations/smart/medications/${id}`);
}

function resend(id, language) {
  return fetchWrapper.post(
    `${baseUrl}/${id}/invitations/resend?language=${language}`
  );
}

function withdraw(id) {
  return fetchWrapper.post(`${baseUrl}/${id}/invitations/withdraw`);
}

function resendBulk(array) {
  return fetchWrapper.post(`${baseUrl}/invitations/resend`, array);
}

function withdrawBulk(array) {
  return fetchWrapper.post(`${baseUrl}/invitations/withdraw`, array);
}

function editInvite(fields, id) {
  if (!fields.phoneNumber) fields.phoneNumber = null;
  if (!fields.email) fields.email = null;
  return fetchWrapper.put(`${baseUrl}/${id}/invitations`, fields);
}

function getInvites(
  searchText,
  pageSize,
  pageIndex,
  sortStr,
  filters,
  showAllInvitations = false
) {
  let url = `${baseUrl}/invitations?searchText=${searchText}&pageSize=${pageSize}&page=${pageIndex}&sortBy=${sortStr}&showAllInvitations=${showAllInvitations}`;
  if (filters) {
    for (const key in filters) {
      let urlFilters = "";
      filters[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }

  return fetchWrapper.get(url, {
    searchText,
    pageSize,
    pageIndex,
    sortStr
  });
}

function getInvite(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/invitations`);
}

function getInvitationHistory(id, searchText, pageSize, page) {
  return fetchWrapper.get(`${baseUrl}/${id}/invitations/history`, {
    searchText,
    pageSize,
    page
  });
}

function getInviteStatus(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/invitations/status`);
}

function getEhrPatient(id, fields) {
  let url = `${baseUrl}/invitations/${id}/search?`;
  for (const key in fields) {
    if (fields[key]) url = `${url}&${key}=${fields[key]}`;
  }
  return fetchWrapper.get(url);
}

function getEhrMedications(id, sourceId) {
  return fetchWrapper.get(
    `${baseUrl}/invitations/${id}/medications/${sourceId}`
  );
}

function addPatients(id) {
  return fetchWrapper.put(`${baseUrl}/${id}/user/add`);
}

function removePatients(id) {
  return fetchWrapper.put(`${baseUrl}/${id}/user/remove`);
}

function mergePatient(id, existingId, fields) {
  return fetchWrapper.post(
    `${baseUrl}/invitations/smart/${id}/merge?existingPatientId=${existingId}`,
    fields
  );
}

function getSignUpDocument(language) {
  return fetchWrapper.get(
    `${baseUrl}/invitations/connectionRequest/signUpInstructions?&language=${language}`
  );
}

function getPatientSignUpDocument(id, language) {
  return fetchWrapper.get(
    `${baseUrl}/${id}/signUpInstructions?&language=${language}`
  );
}

function emailInvite(fields) {
  return fetchWrapper.post(
    `${baseUrl}/invitations/connectionRequest/email`,
    fields
  );
}

function smsInvite(fields) {
  return fetchWrapper.post(
    `${baseUrl}/invitations/connectionRequest/sms`,
    fields
  );
}

function createManageInvitationReport(
  searchText,
  sortStr,
  filter,
  showAllInvitations = false
) {
  if (!searchText) searchText = "";
  let url = `${baseUrl}/invitations/report?searchText=${searchText}&sortBy=${sortStr}&reportDocumentType=2&showAllInvitations=${showAllInvitations}`;
  if (filter) {
    for (const key in filter) {
      let urlFilters = "";
      filter[key].forEach((item) => (urlFilters += `&${key}=${item}`));
      url = `${url}${urlFilters}`;
    }
  }
  return fetchWrapper.post(url);
}
