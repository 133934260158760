import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  TabContent,
  TabPane
} from "reactstrap";
import * as Yup from "yup";
import { SimpleTable } from "../_components";
import { findDuplicates } from "../_helpers";
import { patientInviteService, smartService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useSmartContext } from "../context/smartContext";
import { ReactComponent as Exclamation } from "../images/glyph-exclamation.svg";
import { MedTable } from "../invitations/MedTable";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div>
        <label
          className="g-table-checkbox patient-select"
          data-testid="patient-select"
        >
          <input type="checkbox" ref={resolvedRef} {...rest} />
          <span className="radio"></span>
        </label>
      </div>
    );
  }
);

const DOBCell = ({ value }) => {
  return <span>{new Date(value).toLocaleDateString("en-US")}</span>;
};

export const MobileLink = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [meds, setMeds] = useState([]);
  const [tableMeds, setTableMeds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { patientId, setPatientId, setPatientInviteStatus } = useSmartContext();
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const { alertMethods } = useAlertContext();
  const [data, setData] = useState([]);

  const mounted = React.useRef(true);
  useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const navigate = useNavigate();
  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab]
  );

  const searchValidationSchema = Yup.object({
    // supportId must be at least 8 characters
    supportId: Yup.string().required("Support ID is required").min(5)
  });

  const mergePatients = () => {
    const existingPatientId = selectedPatient[0].original.patientId;
    let medications = meds?.map((med) => {
      if (med.medicationApprovalStatus) return med;
      else return { ...med, medicationApprovalStatus: 1 };
    });
    setSubmitting(true);
    patientInviteService
      .mergePatient(patientId, existingPatientId, medications)
      .then(() => {
        setPatientId(existingPatientId);
        setPatientInviteStatus(2);

        setTimeout(() => {
          setSubmitting(false);
          navigate(`/smart/patients/${existingPatientId}`);
          alertMethods.success("Patients merged successfully", {
            keepAfterRouteChange: true
          });
        }, 500);
      })
      .catch((e) => {
        setSubmitting(false);
        alertMethods.error("Error merging patients");
      });
  };

  // if meds change, set tableMeds to be meds with duplicates detected
  useEffect(() => {
    findDuplicates(meds, setTableMeds, setHasDuplicates);
  }, [meds]);

  const syncMedications = React.useCallback(() => {
    setMeds([]);
    setSubmitting(true);
    setLoading(true);
    patientInviteService
      .getSmartMedications(patientId)
      .then((res) => {
        setLoading(false);
        if (mounted.current) {
          setSubmitting(false);
          toggle("3");
          setMeds(res.medications);
        }
      })
      .catch((e) => {
        navigate("/smart/403");
      });
  }, [patientId, toggle, navigate]);

  function getExistingPatients({ supportId }, { setSubmitting }) {
    setSubmitting(true);
    smartService
      .getExistingPatients(supportId)
      .then((data) => {
        setSubmitting(false);
        toggle("2");
        setData(data);
      })
      .catch((e) => {
        setSubmitting(false);
        alertMethods.error("Error getting existing patients");
      });
  }
  const patientColumns = React.useMemo(
    () => [
      {
        Header: "Support ID",
        accessor: "supportId",
        disableSortBy: true
      },
      {
        Header: "Name in App",
        accessor: "nameInApp"
      },
      {
        Header: "DoB",
        accessor: "birthDate",
        Cell: (props) => {
          return <DOBCell value={props.value} />;
        }
      },
      {
        id: "selection",
        Header: "",
        Cell: (props) => {
          if (
            props.rows.filter((row) => row.isSelected).length < 1 ||
            props.row.isSelected
          ) {
            return (
              <div>
                <IndeterminateCheckbox
                  {...props.row.getToggleRowSelectedProps()}
                />
              </div>
            );
          } else {
            return (
              <div>
                <label
                  className="g-table-checkbox"
                  data-testid="patient-select"
                >
                  <input
                    type="checkbox"
                    onChange={() => {
                      props.toggleAllRowsSelected(false);
                      props.row.toggleRowSelected(true);
                    }}
                    style={props.row.getToggleRowSelectedProps().style}
                  />
                  <span className="radio"></span>
                </label>
              </div>
            );
          }
        }
      }
    ],
    []
  );

  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center smart-container">
        <Pagination
          size="sm"
          role="tablist"
          aria-label="Page navigation example"
        >
          <PaginationItem className="import-circles">
            <PaginationLink
              className={classnames({
                active: activeTab === "1"
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              1
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="import-circles">
            <PaginationLink
              className={classnames({
                active: activeTab === "2"
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              2
            </PaginationLink>
          </PaginationItem>
          <PaginationItem className="import-circles">
            <PaginationLink
              className={classnames({
                active: activeTab === "3"
              })}
              onClick={() => {
                toggle("3");
              }}
            >
              3
            </PaginationLink>
          </PaginationItem>
        </Pagination>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" data-testid="tab1">
            <Formik
              initialValues={{
                supportId: ""
              }}
              validationSchema={searchValidationSchema}
              onSubmit={getExistingPatients}
            >
              {({ errors, touched, isSubmitting, isValid, dirty }) => {
                return (
                  <Form>
                    <div className="d-flex flex-column align-items-center justify-content-center pb-3">
                      <h3 className="text-center mb-6">
                        Enroll the Patient's App Support ID
                      </h3>
                      <div className="pb-4 mx-6">
                        If the patient is already using the app, their Support
                        ID <br></br> can be found in the top left menu of their
                        mobile app
                      </div>
                      <div className="card bg-white align-self-center w-30rem">
                        <div className="card-body">
                          <div className="form-group mx-5">
                            <label htmlFor="supportId">
                              Patient's Support ID
                            </label>
                            <Field
                              name="supportId"
                              id="supportId"
                              placeholder="Enter Support ID"
                              type="text"
                              className={classnames(
                                {
                                  "is-invalid":
                                    errors.supportId && touched.supportId
                                },
                                "form-control"
                              )}
                            />
                            <ErrorMessage
                              name="supportId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-row justify-content-between mt-4 w-100">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-45 mb-4 mb-md-0"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          disabled={isSubmitting || !isValid || !dirty}
                          className="btn btn-primary w-45"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          Search
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </TabPane>
          <TabPane tabId="2" data-testid="tab2">
            <div className="d-flex flex-column align-items-center justify-content-center pb-3">
              <h3 className="text-center mb-6">
                Select the App User to Link to this Patient
              </h3>
              <div className="pb-4 mx-6">
                Please review the patient information below and select the{" "}
                <br></br> mobile app user to link to this patient's profile.
              </div>
              <div className="card bg-white align-self-center invite">
                <div className="card-body px-4 px-lg-6">
                  <SimpleTable
                    data={data}
                    columns={patientColumns}
                    pageCount={1}
                    noSearch={true}
                    emptyColSpan={4}
                    emptyMsg="No patients found"
                    setSelectedPatient={setSelectedPatient}
                  />
                </div>
              </div>

              <div className="form-row justify-content-between mt-4 w-100">
                <button
                  type="button"
                  className="btn btn-outline-primary w-45 mb-4 mb-md-0"
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Back
                </button>
                <button
                  type="button"
                  disabled={!selectedPatient?.length || submitting}
                  className="btn btn-primary w-45"
                  onClick={syncMedications}
                >
                  {submitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Next
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane tabId="3" data-testid="tab3">
            <h3 className="text-center mb-5">Configure Medication List</h3>
            <p className="text-center">
              Choose which medications to sync to the patient’s EveryDose
              <br></br>mobile app. Add or edit information as necessary
            </p>
            <div className="import-table smart-table pt-1 pb-0">
              <div className="px-0 pt-0 pb-0">
                <MedTable
                  meds={tableMeds}
                  setMeds={setMeds}
                  syncMedications={syncMedications}
                  loading={loading}
                  smartVersion={true}
                  hasDuplicates={hasDuplicates}
                />
              </div>
            </div>
            {hasDuplicates && (
              <p className="text-center text-secondary">
                <Exclamation
                  className="glyph-danger mb-1 mr-1"
                  alt="exclamation"
                />
                Remove/unselect duplicate medications before proceeding.
              </p>
            )}
            <div className="d-flex flex-md-row flex-column justify-content-center align-items-center mt-4 mb-5 mx-md-0 mx-4">
              <button
                type="button"
                // disabled={loading}
                data-testid="back-to-start"
                className="btn btn-outline-primary w-25 mr-md-5 mr-0 mb-md-0 mb-4"
                onClick={() => {
                  toggle("1");
                }}
              >
                Back
              </button>
              <button
                type="button"
                disabled={loading || hasDuplicates || submitting}
                className="btn btn-primary w-25"
                onClick={mergePatients}
              >
                {submitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Complete
              </button>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
