export const therapeuticCategories = {
  "anti-infectives": {
    "amebicides": [],
    "anthelmintics": [],
    "antifungals": [
      "polyenes",
      "azole antifungals",
      "miscellaneous antifungals",
      "echinocandins"
    ],
    "antimalarial agents": [
      "antimalarial quinolines",
      "miscellaneous antimalarials",
      "antimalarial combinations"
    ],
    "antituberculosis agents": [
      "aminosalicylates",
      "thiocarbamide derivatives",
      "rifamycin derivatives",
      "streptomyces derivatives",
      "miscellaneous antituberculosis agents",
      "antituberculosis combinations",
      "hydrazide derivatives",
      "diarylquinolines"
    ],
    "antiviral agents": [
      "protease inhibitors",
      "NRTIs",
      "miscellaneous antivirals",
      "NNRTIs",
      "adamantane antivirals",
      "purine nucleosides",
      "neuraminidase inhibitors",
      "antiviral combinations",
      "antiviral interferons",
      "antiviral chemokine receptor antagonist",
      "integrase strand transfer inhibitor",
      "antiviral boosters",
      "NS5A inhibitors"
    ],
    "carbapenems": [],
    "cephalosporins": [
      "first generation cephalosporins",
      "second generation cephalosporins",
      "third generation cephalosporins",
      "fourth generation cephalosporins",
      "fifth generation cephalosporins",
      "cephalosporins/beta-lactamase inhibitors",
      "other cephalosporins"
    ],
    "leprostatics": [],
    "macrolide derivatives": [
      "macrolides",
      "ketolides"
    ],
    "miscellaneous antibiotics": [],
    "penicillins": [
      "penicillinase resistant penicillins",
      "antipseudomonal penicillins",
      "aminopenicillins",
      "penicillins/beta-lactamase inhibitors",
      "natural penicillins"
    ],
    "quinolones": [],
    "sulfonamides": [],
    "tetracyclines": [],
    "urinary anti-infectives": [],
    "aminoglycosides": [],
    "lincomycin derivatives": [],
    "glycylcyclines": [],
    "glycopeptide antibiotics": [],
    "oxazolidinone antibiotics": [],
    "carbapenems/beta-lactamase inhibitors": [],
    "streptogramins": []
  },
  "antineoplastics": {
    "alkylating agents": [],
    "antineoplastic antibiotics": [],
    "antimetabolites": [],
    "antineoplastic hormones": [],
    "miscellaneous antineoplastics": [],
    "mitotic inhibitors": [],
    "antineoplastic interferons": [],
    "antineoplastic detoxifying agents": [],
    "multikinase inhibitors": [],
    "BCR-ABL tyrosine kinase inhibitors": [],
    "CD52 monoclonal antibodies": [],
    "CD33 monoclonal antibodies": [],
    "CD20 monoclonal antibodies": [],
    "VEGF/VEGFR  inhibitors": [],
    "mTOR inhibitors": [],
    "EGFR inhibitors": [],
    "HER2 inhibitors": [],
    "histone deacetylase inhibitors": [],
    "trifunctional monoclonal antibodies": [],
    "anti-CTLA-4 monoclonal antibodies": [],
    "CD30 monoclonal antibodies": [],
    "hedgehog pathway inhibitors": [],
    "proteasome inhibitors": [],
    "CD38 monoclonal antibodies": [],
    "anti-PD-1 monoclonal antibodies": [],
    "PARP inhibitors": [],
    "antineoplastic combinations": [],
    "PI3K inhibitors": [],
    "CDK 4/6 inhibitors": [],
    "malignancy photosensitizers": [],
    "BTK inhibitors": [],
    "CD19 monoclonal antibodies": [],
    "bispecific antibodies": [
      "bispecific T-cell engagers (BiTE)",
      "miscellaneous bispecific antibodies"
    ]
  },
  "biologicals": {
    "antitoxins and antivenins": [],
    "in vivo diagnostic biologicals": [],
    "erythropoiesis-stimulating agents": [],
    "hematopoietic stem cell mobilizer": [],
    "miscellaneous erythropoiesis agents": []
  },
  "cardiovascular agents": {
    "agents for hypertensive emergencies": [],
    "angiotensin converting enzyme (ACE) inhibitors": [],
    "antiadrenergic agents, peripherally acting": [],
    "antiadrenergic agents, centrally acting": [],
    "antianginal agents": [],
    "antiarrhythmic agents": [
      "group I antiarrhythmics",
      "group II antiarrhythmics",
      "group III antiarrhythmics",
      "group IV antiarrhythmics",
      "group V antiarrhythmics"
    ],
    "beta-adrenergic blocking agents": [
      "beta blockers, cardioselective",
      "beta blockers, non-cardioselective"
    ],
    "calcium channel blocking agents": [],
    "diuretics": [
      "loop diuretics",
      "potassium-sparing diuretics",
      "thiazide and thiazide-like diuretics",
      "carbonic anhydrase inhibitors",
      "miscellaneous diuretics"
    ],
    "inotropic agents": [],
    "miscellaneous cardiovascular agents": [],
    "peripheral vasodilators": [],
    "vasodilators": [],
    "vasopressors": [],
    "antihypertensive combinations": [
      "ACE inhibitors with thiazides",
      "antiadrenergic agents (central) with thiazides",
      "antiadrenergic agents (peripheral) with thiazides",
      "miscellaneous antihypertensive combinations",
      "beta blockers with thiazides",
      "angiotensin II inhibitors with thiazides",
      "beta blockers with calcium channel blockers",
      "potassium sparing diuretics with thiazides",
      "ACE inhibitors with calcium channel blocking agents",
      "angiotensin II inhibitors with calcium channel blockers"
    ],
    "angiotensin II inhibitors": [],
    "agents for pulmonary hypertension": [],
    "vasopressin antagonists": [],
    "sclerosing agents": [],
    "aldosterone receptor antagonists": [],
    "renin inhibitors": [],
    "prostaglandin D2 antagonists": [],
    "anticholinergic chronotropic agents": [],
    "catecholamines": [],
    "angiotensin receptor blockers and neprilysin inhibitors": [],
    "neprilysin inhibitors": []
  },
  "central nervous system agents": {
    "analgesics": [
      "miscellaneous analgesics",
      "narcotic analgesics",
      "nonsteroidal anti-inflammatory agents",
      "salicylates",
      "analgesic combinations",
      "narcotic analgesic combinations",
      "antimigraine agents",
      "cox-2 inhibitors",
      "CGRP inhibitors"
    ],
    "anticonvulsants": [
      "hydantoin anticonvulsants",
      "succinimide anticonvulsants",
      "barbiturate anticonvulsants",
      "oxazolidinedione anticonvulsants",
      "benzodiazepine anticonvulsants",
      "miscellaneous anticonvulsants",
      "dibenzazepine anticonvulsants",
      "fatty acid derivative anticonvulsants",
      "gamma-aminobutyric acid reuptake inhibitors",
      "gamma-aminobutyric acid analogs",
      "triazine anticonvulsants",
      "carbamate anticonvulsants",
      "pyrrolidine anticonvulsants",
      "carbonic anhydrase inhibitor anticonvulsants",
      "urea anticonvulsants",
      "neuronal potassium channel openers",
      "AMPA receptor antagonists"
    ],
    "antiemetic/antivertigo agents": [
      "5HT3 receptor antagonists",
      "phenothiazine antiemetics",
      "anticholinergic antiemetics",
      "miscellaneous antiemetics",
      "NK1 receptor antagonists"
    ],
    "antiparkinson agents": [
      "anticholinergic antiparkinson agents",
      "miscellaneous antiparkinson agents",
      "dopaminergic antiparkinsonism agents"
    ],
    "anxiolytics, sedatives, and hypnotics": [
      "barbiturates",
      "benzodiazepines",
      "miscellaneous anxiolytics, sedatives and hypnotics"
    ],
    "CNS stimulants": [],
    "general anesthetics": [],
    "muscle relaxants": [
      "neuromuscular blocking agents",
      "skeletal muscle relaxants",
      "skeletal muscle relaxant combinations"
    ],
    "miscellaneous central nervous system agents": [],
    "anorexiants": [],
    "cholinergic agonists": [],
    "cholinesterase inhibitors": [],
    "drugs used in alcohol dependence": [],
    "VMAT2 inhibitors": [],
    "noradrenergic uptake inhibitors for ADHD": []
  },
  "coagulation modifiers": {
    "anticoagulants": [
      "heparins",
      "coumarins and indanediones",
      "thrombin inhibitors",
      "factor Xa inhibitors"
    ],
    "antiplatelet agents": [
      "platelet aggregation inhibitors",
      "glycoprotein platelet inhibitors",
      "protease-activated receptor-1 antagonists"
    ],
    "heparin antagonists": [],
    "miscellaneous coagulation modifiers": [],
    "thrombolytics": [],
    "platelet-stimulating agents": [],
    "anticoagulant reversal agents": [],
    "hereditary angioedema agents": []
  },
  "gastrointestinal agents": {
    "antacids": [],
    "antidiarrheals": [],
    "digestive enzymes": [],
    "gallstone solubilizing agents": [],
    "GI stimulants": [],
    "H2 antagonists": [],
    "laxatives": [],
    "miscellaneous GI agents": [],
    "proton pump inhibitors": [],
    "5-aminosalicylates": [],
    "H. pylori eradication agents": [],
    "functional bowel disorder agents": [
      "anticholinergics/antispasmodics",
      "serotoninergic neuroenteric modulators",
      "chloride channel activators",
      "peripheral opioid receptor antagonists",
      "guanylate cyclase-C agonists",
      "peripheral opioid receptor mixed agonists/antagonists",
      "NHE3 inhibitors"
    ]
  },
  "hormones/hormone modifiers": {
    "adrenal cortical steroids": [
      "corticotropin",
      "glucocorticoids",
      "mineralocorticoids"
    ],
    "miscellaneous hormones": [],
    "sex hormones": [
      "contraceptives",
      "androgens and anabolic steroids",
      "estrogens",
      "gonadotropins",
      "progestins",
      "sex hormone combinations",
      "miscellaneous sex hormones",
      "gonadotropin-releasing hormone and analogs"
    ],
    "thyroid hormones": [],
    "5-alpha-reductase inhibitors": [],
    "growth hormones": [],
    "insulin-like growth factor": [],
    "growth hormone receptor blockers": [],
    "prolactin inhibitors": [],
    "adrenal corticosteroid inhibitors": [],
    "calcitonin": [],
    "antigonadotropic agents": [],
    "antidiuretic hormones": [],
    "somatostatin and somatostatin analogs": [],
    "selective estrogen receptor modulators": [],
    "parathyroid hormone and analogs": [],
    "gonadotropin-releasing hormone antagonists": [],
    "antiandrogens": [],
    "antithyroid agents": [],
    "aromatase inhibitors": [],
    "estrogen receptor antagonists": [],
    "synthetic ovulation stimulants": [],
    "progesterone receptor modulators": [],
    "calcimimetics": [],
    "melanocortin receptor agonists": []
  },
  "miscellaneous agents": {
    "antidotes": [],
    "chelating agents": [],
    "cholinergic muscle stimulants": [],
    "local injectable anesthetics": [],
    "miscellaneous uncategorized agents": [],
    "psoralens": [],
    "illicit (street) drugs": [],
    "antirheumatics": [],
    "antipsoriatics": [],
    "viscosupplementation agents": [],
    "smoking cessation agents": [],
    "phosphate binders": [],
    "local injectable anesthetics with corticosteroids": [],
    "cation exchange resins": [],
    "peripheral opioid receptor agonists": []
  },
  "genitourinary tract agents": {
    "erectile dysfunction agents": [],
    "urinary antispasmodics": [],
    "urinary pH modifiers": [],
    "miscellaneous genitourinary tract agents": [],
    "uterotonic agents": [],
    "tocolytic agents": [],
    "alpha-adrenoreceptor antagonists": []
  },
  "nutritional products": {
    "iron products": [],
    "minerals and electrolytes": [],
    "oral nutritional supplements": [],
    "vitamins": [],
    "vitamin and mineral combinations": [],
    "intravenous nutritional products": []
  },
  "respiratory agents": {
    "antihistamines": [],
    "antitussives": [],
    "bronchodilators": [
      "methylxanthines",
      "adrenergic bronchodilators",
      "bronchodilator combinations",
      "anticholinergic bronchodilators"
    ],
    "decongestants": [],
    "expectorants": [],
    "miscellaneous respiratory agents": [],
    "respiratory inhalant products": [
      "inhaled corticosteroids",
      "mast cell stabilizers",
      "inhaled anti-infectives"
    ],
    "antiasthmatic combinations": [],
    "upper respiratory combinations": [],
    "leukotriene modifiers": [],
    "lung surfactants": [],
    "mucolytics": [],
    "selective phosphodiesterase-4 inhibitors": []
  },
  "topical agents": {
    "anorectal preparations": [],
    "antiseptic and germicides": [],
    "dermatological agents": [
      "topical anti-infectives",
      "topical steroids",
      "topical anesthetics",
      "miscellaneous topical agents",
      "topical steroids with anti-infectives",
      "topical acne agents",
      "topical antipsoriatics",
      "topical emollients",
      "topical antibiotics",
      "topical antivirals",
      "topical antifungals",
      "topical debriding agents",
      "topical depigmenting agents",
      "topical antihistamines",
      "topical astringents",
      "topical keratolytics",
      "topical non-steroidal anti-inflammatories",
      "topical antineoplastics",
      "topical photochemotherapeutics",
      "topical rubefacient",
      "topical anti-rosacea agents"
    ],
    "mouth and throat products": [],
    "ophthalmic preparations": [
      "ophthalmic anti-infectives",
      "ophthalmic glaucoma agents",
      "ophthalmic steroids",
      "ophthalmic steroids with anti-infectives",
      "ophthalmic anti-inflammatory agents",
      "ophthalmic lubricants and irrigations",
      "miscellaneous ophthalmic agents",
      "ophthalmic antihistamines and decongestants",
      "mydriatics",
      "ophthalmic anesthetics",
      "ophthalmic diagnostic agents",
      "ophthalmic surgical agents",
      "anti-angiogenic ophthalmic agents"
    ],
    "otic preparations": [
      "otic anti-infectives",
      "otic steroids with anti-infectives",
      "miscellaneous otic agents",
      "otic steroids",
      "otic anesthetics",
      "cerumenolytics"
    ],
    "sterile irrigating solutions": [],
    "vaginal preparations": [
      "spermicides",
      "vaginal anti-infectives",
      "miscellaneous vaginal agents"
    ],
    "nasal preparations": [
      "nasal lubricants and irrigations",
      "nasal steroids",
      "nasal antihistamines and decongestants",
      "nasal anti-infectives"
    ],
    "topical allergy diagnostic agents": []
  },
  "plasma expanders": {},
  "alternative medicines": {
    "nutraceutical products": [],
    "herbal products": [],
    "probiotics": []
  },
  "psychotherapeutic agents": {
    "psychotherapeutic combinations": [],
    "antidepressants": [
      "miscellaneous antidepressants",
      "SSRI antidepressants",
      "tricyclic antidepressants",
      "monoamine oxidase inhibitors",
      "phenylpiperazine antidepressants",
      "tetracyclic antidepressants",
      "SSNRI antidepressants"
    ],
    "antipsychotics": [
      "miscellaneous antipsychotic agents",
      "phenothiazine antipsychotics",
      "thioxanthenes",
      "atypical antipsychotics"
    ],
    "antimanic agents": []
  },
  "immunologic agents": {
    "immune globulins": [],
    "immunosuppressive agents": [
      "calcineurin inhibitors",
      "TNF alpha inhibitors",
      "interleukin inhibitors",
      "selective immunosuppressants",
      "other immunosuppressants"
    ],
    "immunostimulants": [
      "bacterial vaccines",
      "colony stimulating factors",
      "viral vaccines",
      "interferons",
      "vaccine combinations",
      "interleukins",
      "other immunostimulants",
      "therapeutic vaccines"
    ]
  },
  "radiologic agents": {
    "radiopharmaceuticals": [
      "diagnostic radiopharmaceuticals",
      "therapeutic radiopharmaceuticals"
    ],
    "radiocontrast agents": [
      "miscellaneous iodinated contrast media",
      "lymphatic staining agents",
      "magnetic resonance imaging contrast media",
      "non-iodinated contrast media",
      "ultrasound contrast media",
      "non-ionic iodinated contrast media",
      "ionic iodinated contrast media",
      "miscellaneous diagnostic dyes"
    ],
    "radiologic adjuncts": [
      "cardiac stressing agents",
      "radiologic conjugating agents"
    ]
  },
  "metabolic agents": {
    "antihyperlipidemic agents": [
      "HMG-CoA reductase inhibitors (statins)",
      "miscellaneous antihyperlipidemic agents",
      "fibric acid derivatives",
      "bile acid sequestrants",
      "cholesterol absorption inhibitors",
      "antihyperlipidemic combinations",
      "PCSK9 inhibitors"
    ],
    "antidiabetic agents": [
      "sulfonylureas",
      "biguanides",
      "insulin",
      "alpha-glucosidase inhibitors",
      "thiazolidinediones",
      "meglitinides",
      "miscellaneous antidiabetic agents",
      "antidiabetic combinations",
      "dipeptidyl peptidase 4 inhibitors",
      "amylin analogs",
      "GLP-1 receptor agonists",
      "SGLT-2 inhibitors"
    ],
    "antigout agents": [],
    "antihyperuricemic agents": [],
    "glucose elevating agents": [],
    "peripherally acting antiobesity agents": [],
    "lysosomal enzymes": [],
    "miscellaneous metabolic agents": [],
    "bone resorption inhibitors": [
      "bisphosphonates",
      "miscellaneous bone resorption inhibitors"
    ],
    "CFTR modulators": [],
    "urea cycle disorder agents": [],
    "bone morphogenetic proteins": [],
    "CFTR combinations": [],
    "transthyretin stabilizers": []
  },
  "medical gas": {},
  "allergenics": {},
  "renal replacement solutions": {},
  "investigational drugs": {},
  "monobactams": {},
  "beta-lactamase inhibitors": {},
  "antiviral monoclonal antibodies": {}
}