import classnames from "classnames";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useUserContext } from "../context/userContext";

const SidebarComponent = lazy(() =>
  import("@syncfusion/ej2-react-navigations").then((module) => ({
    default: module.SidebarComponent
  }))
);

const SidebarNav = ({ details, isSmartSession }) => {
  return (
    <div className="p-4">
      <h4 className="text-light p-4">{details.title}</h4>

      {details.items.map((item, i) => (
        <NavLink
          end={details.title !== "Admin Center"}
          to={isSmartSession ? `/smart${item.link}` : `${item.link}`}
          key={item.text}
          id={item.text}
          className={classnames(
            //   { active: activeIdx === i },
            "nav-link text-light"
          )}
          onClick={() => {
            const btn = document.getElementById(item.text);
            btn.blur();
          }}
        >
          {item.text}
        </NavLink>
      ))}
    </div>
  );
};

const Sidebar = ({ details, isSmartSession }) => {
  const { userState } = useUserContext();
  const { user } = userState;
  const sidebarRef = useRef(null);
  const onCreate = () => {
    if (sidebarRef.current && sidebarRef.current !== null)
      sidebarRef.current.element.style.visibility = "";
  };

  return (
    <Suspense
      fallback={
        <div
          id="permanent-sidebar"
          className={classnames(
            { "h-100vh ml-neg-5rem": user?.isSmartSession },
            { "h-vh ml-neg-3-3rem": !user?.isSmartSession },
            "bg-dark mr-6"
          )}
        >
          <SidebarNav details={details} isSmartSession={isSmartSession} />
        </div>
      }
    >
      <SidebarComponent
        className={classnames({ "smart-sidebar": isSmartSession })}
        id="permanent-sidebar"
        mediaQuery={window.matchMedia("(min-width: 1280px)")}
        created={onCreate}
        type="push"
        target={details.target}
        enableGestures={false}
        animate={false}
        ref={sidebarRef}
      >
        <SidebarNav details={details} isSmartSession={isSmartSession} />
      </SidebarComponent>
    </Suspense>
  );
};

SidebarNav.propTypes = {
  details: PropTypes.object,
  isSmartSession: PropTypes.bool
};

Sidebar.propTypes = {
  details: PropTypes.object,
  isSmartSession: PropTypes.bool
};

export { Sidebar };
