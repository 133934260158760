import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardHeader } from "reactstrap";
import {
  convertUTCTimeToLocalTime,
  outreachTypeOptions
} from "../../../_helpers";
import dayjs from "dayjs";

export const OutreachCard = ({ outreach }) => {
  const [latestOutreach, setLatestOutreach] = useState(null);
  useEffect(() => {
    // sort outreach by due date, newest first, then set latestOutreach to the first item in the sorted array
    let sortedOutreach = outreach.sort(
      (a, b) => new Date(b.dueDate) - new Date(a.dueDate)
    );
    let typeText = outreachTypeOptions.find(
      (item) => Number(item.value) === sortedOutreach[0].type
    );

    setLatestOutreach({
      ...sortedOutreach[0],
      typeText: typeText.label.toLowerCase()
    });
  }, [outreach]);

  return (
    <Card className="bg-white">
      <CardHeader>
        <h6>Last Outreach</h6>
      </CardHeader>
      <CardBody className="py-4">
        {latestOutreach?.contactedByUser} contacted this patient by{" "}
        {latestOutreach?.typeText} on{" "}
        {dayjs(latestOutreach?.dueDate).format("M/D/YYYY")}{" "}
        {latestOutreach?.dueTime && (
          <span>at {convertUTCTimeToLocalTime(latestOutreach?.dueTime)}</span>
        )}
      </CardBody>
    </Card>
  );
};

OutreachCard.propTypes = {
  outreach: PropTypes.array
};
