import React, { lazy } from "react";

const ReactToPrint = lazy(() => import("react-to-print"));
const ReactToPrintButton = ({ componentRef, trigger }) => (
  <ReactToPrint trigger={trigger} content={() => componentRef.current} />
);

const ReactToPrintWrapper = lazy(async () => {
  const module = await import("react-to-print");

  const { useReactToPrint } = module;

  const PrintButton = ({
    componentRef,
    onBeforeGetContent,
    onAfterPrint,
    buttonComponent,
    classes,
    documentTitle,
  }) => {
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      onBeforeGetContent: () => onBeforeGetContent?.(),
      onAfterPrint: () => onAfterPrint?.(),
      documentTitle: documentTitle,
    });

    return (
      <button className={classes} onClick={handlePrint}>
        {buttonComponent}
      </button>
    );
  };
  return { default: PrintButton };
});

export { ReactToPrintWrapper as PrintButton, ReactToPrintButton };
