import classnames from "classnames";
import download from "downloadjs";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  CloseButton,
  ExcelViewer,
  MyDropdown,
  NewGTable,
  PdfViewer,
  RowExpander
} from "../_components";
import { UseMobileMediaQuery } from "../_helpers/media-queries";
import { reportService } from "../_services";
import { useAlertContext } from "../context/alertContext";
import { useTableStateContext } from "../context/stateContext";
import { useUserContext } from "../context/userContext";
import { ReactComponent as DownloadIcon } from "../images/download.svg";
import { ReactComponent as GylphMenu } from "../images/glyph_menu_2.svg";
import { ReactComponent as ViewIcon } from "../images/view.svg";
import { populationReportTypes, reportTypes } from "../_helpers";
import PropTypes from "prop-types";

const reportStatus = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Generated" }
];

const reportSubTypes = [
  // { value: 1, label: "User List" },
  // { value: 2, label: "Patient List" },
  // { value: 3, label: "Group User List" },
  // { value: 4, label: "Group Patient List" },
  // { value: 5, label: "User Invitations" },
  { value: 50, label: "Patient Invitations", permissionNum: 11 },
  { value: 51, label: "User Patient List", permissionNum: 23 },
  { value: 52, label: "User Tasks", permissionNum: 28 },
  { value: 53, label: "Connection Requests", permissionNum: 16 }
  // { value: 100, label: "Patient Details" },
  // { value: 101, label: "Medication List" },
  // { value: 102, label: "Adherence Summary" },
  // { value: 103, label: "Health Metrics" },
  // { value: 104, label: "Outreach" },
  // { value: 105, label: "Tasks" },
  // { value: 106, label: "Leaflets" },
  // { value: 107, label: "Drug-to-Drug Interactions" },
  // { value: 108, label: "Food Interactions" }
];

const formats = [
  { value: 1, label: "PDF" },
  { value: 2, label: "XLSX" }
];
const headerFilters = [
  {
    options: reportStatus,
    column: "status",
    name: "Report Status",
    multi: true
  },
  {
    options: reportSubTypes,
    column: "reportType",
    name: "Sub Type",
    multi: true
  }
];
const patientLevelFilterNames = reportTypes.map(({ fullName }) => fullName);
const headerFiltersWithoutPopulation = [
  {
    options: reportStatus,
    column: "status",
    name: "Report Status",
    multi: true
  },
  {
    options: reportSubTypes.filter((item) =>
      patientLevelFilterNames.includes(item.label)
    ),
    column: "reportType",
    name: "Sub Type",
    multi: true
  }
];
const DateCell = ({ value }) => {
  return <div>{dayjs(value).format("M/D/YYYY")}</div>;
};
DateCell.propTypes = {
  value: PropTypes.string
};

const SubReportCell = ({ value, isMobileWidth, row }) => {
  let desc = reportSubTypes.find((val) => {
    return val.value === value;
  });

  return (
    <div className="d-flex justify-content-between align-items-center">
      {desc?.label || "--"}
      {isMobileWidth && <RowExpander {...row} />}
    </div>
  );
};
SubReportCell.propTypes = {
  value: PropTypes.number,
  isMobileWidth: PropTypes.bool,
  row: PropTypes.object
};

const StatusCell = ({ value }) => {
  let desc = reportStatus.find((val) => {
    return val.value === value;
  });
  return <div>{desc?.label}</div>;
};
StatusCell.propTypes = {
  value: PropTypes.number
};

const FormatCell = ({ value }) => {
  let desc = formats.find((val) => {
    return val.value === value;
  });

  return <>{desc ? <div>{desc?.label}</div> : <div>--</div>}</>;
};
FormatCell.propTypes = {
  value: PropTypes.number
};

const ActionsCell = ({
  row,
  setDataChange,
  type,
  toggleModal,
  setFile,
  setIsPDF,
  alertMethods,
  isMobileWidth,
  setNumberOfFilters
}) => {
  const mounted = useRef(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  function viewFile(format) {
    // if row.reportType > 100, find reportType in reportTypes enum
    // else find it in populationReportTypes enum
    if (row.reportType > 100) {
      const reportType = reportTypes.find((val) => {
        return val.value === row.reportType;
      });
      // if reportType is found, count the number of properties on reportType.fields and set it to numberOfFilters
      if (reportType) {
        setNumberOfFilters(Object.keys(reportType.fields).length);
      }
    } else {
      const reportType = populationReportTypes.find((val) => {
        return val.value === row.reportType;
      });
      // if reportType is found, count the number of properties on reportType.fields and set it to numberOfFilters
      if (reportType) {
        setNumberOfFilters(
          Object.keys(reportType.fields).length +
            (reportType.fields.filterBy?.length - 1 || 0)
        );
      }
    }

    if (format === 1) {
      setIsPDF(true);
    } else setIsPDF(false);
    if (type === "archived") {
      reportService
        .getArchivedStream(row.id)
        .then((data) => {
          setFile(data);
          toggleModal();
        })
        .catch((err) => {
          console.log(err);
          alertMethods.error("Something went wrong. Please try again.");
        });
    } else {
      reportService
        .getStream(row.id)
        .then((data) => {
          setFile(data);
          toggleModal();
        })
        .catch((err) => {
          console.log(err);
          alertMethods.error("Something went wrong. Please try again.");
        });
    }
  }

  function downloadReport(format) {
    if (type === "archived") {
      reportService
        .downloadArchivedReport(row.id)
        .then((response) => {
          if (mounted.current) {
            if (window.navigator?.msSaveOrOpenBlob) {
              window.navigator?.msSaveOrOpenBlob(
                response,
                type === 1 ? "report.pdf" : "report.xlsx"
              );
            } else if (format === 1)
              download(response, "report.pdf", "application/pdf");
            else
              download(
                response,
                "report.xlsx",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              );
          }
          alertMethods.success("Report downloaded successfully");
        })
        .catch((err) => {
          alertMethods.error("Something went wrong. Please try again.");
        });
    } else {
      reportService
        .downloadReport(row.id)
        .then((response) => {
          if (window.navigator?.msSaveOrOpenBlob) {
            window.navigator?.msSaveOrOpenBlob(
              response,
              type === 1 ? "report.pdf" : "report.xlsx"
            );
          } else if (mounted.current) {
            if (format === 1)
              download(response, "report.pdf", "application/pdf");
            else
              download(
                response,
                "report.xlsx",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              );
          }
          alertMethods.success("Report downloaded successfully");
        })
        .catch((err) => {
          alertMethods.error("Something went wrong. Please try again.");
        });
    }
  }
  function archiveReport() {
    reportService
      .archiveReport(row.id)
      .then((res) => {
        if (setDataChange) setDataChange((current) => !current);
        else navigate("/reports/archived");
        alertMethods.success("Report archived successfully");
      })
      .catch((err) => {
        alertMethods.error("Something went wrong. Please try again.");
        alertMethods.error("Error archiving report");
      });
  }
  const dropdownItems = [
    {
      name: "Archive Report",
      link: "",
      onClick: () => {
        archiveReport();
      }
    }
  ];

  return (
    <div className="d-flex align-items-center justify-content-end">
      {row.status === 2 ? (
        <>
          <button
            className="btn btn-link"
            onClick={() => viewFile(row.documentType)}
          >
            <ViewIcon
              className="mr-3 glyph-fill-primary h18-w18-style"
              aria-label="view"
            />
          </button>
          <button
            className="btn btn-link"
            onClick={() => downloadReport(row.documentType)}
          >
            <DownloadIcon
              className="mr-3 glyph-fill-primary h18-w18-style"
              aria-label="download"
            />
          </button>
        </>
      ) : (
        <></>
      )}
      {type !== "archived" ? (
        <>
          {!isMobileWidth ? (
            <MyDropdown
              details={{
                name: "row menu",
                glyph: <GylphMenu className="w-10px" aria-label="menu" />,
                items: dropdownItems
              }}
              // container="body"
              direction="right"
            />
          ) : (
            <button className="btn btn-link" onClick={archiveReport}>
              Archive
            </button>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
ActionsCell.propTypes = {
  row: PropTypes.object,
  setDataChange: PropTypes.func,
  type: PropTypes.string,
  toggleModal: PropTypes.func,
  setFile: PropTypes.func,
  setIsPDF: PropTypes.func,
  alertMethods: PropTypes.object,
  isMobileWidth: PropTypes.bool,
  setNumberOfFilters: PropTypes.func
};

const ReportsTable = ({ type, patientId, dataChange, setDataChange }) => {
  const location = useLocation();
  const { state } = location;
  const isMobileWidth = UseMobileMediaQuery();
  const { tableState, updateReportsTableState } = useTableStateContext();
  const [file, setFile] = useState("");
  const { alertMethods } = useAlertContext();
  const [startDate, setStartDate] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [tableHeader, setTableHeader] = useState(undefined);
  const [visibleHeaderFilters, setVisibleHeaderFilters] =
    useState(headerFilters);

  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const { userState } = useUserContext();
  const { user, tenantFeatures, userPermissions } = userState;
  const fetchIdRef = React.useRef(0);
  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true; // Will set it to true on mount ...
    return () => {
      mounted.current = false;
    }; // ... and to false on unmount
  }, []);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  useEffect(() => {
    if (type === "archived") {
      const thirtyDaysAgo = new Date();
      setStartDate(thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30));
    }
  }, [type]);

  useEffect(() => {
    if (type) {
      setVisibleHeaderFilters(
        type === "archived" ? undefined : headerFiltersWithoutPopulation
      );
    } else
      setVisibleHeaderFilters([
        {
          options: reportStatus,
          column: "status",
          name: "Report Status",
          multi: true
        },
        {
          options: reportSubTypes.filter((subType) =>
            userPermissions?.includes(subType.permissionNum)
          ),
          column: "reportType",
          name: "Sub Type",
          multi: true
        }
      ]);
  }, [type, userPermissions]);

  const fetchData = React.useCallback(
    ({ filtersArr, sortStr, pageSize, pageIndex }) => {
      let sort = {};
      if (sortStr) {
        sort.id = sortStr.slice(0, sortStr.indexOf("."));
        sort.desc = sortStr.slice(sortStr.indexOf(".") + 1) === "DESC";
      }

      let filterArr = {};
      if (filtersArr?.length) {
        filtersArr.forEach((filter) => {
          if (filter.value.length) filterArr[filter.id] = filter.value;
        });
      }
      if (type === "patient" && !patientId) return;
      if (patientId) {
        filterArr.patientId = [patientId];
      }
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      if (type === "archived") {
        if (fetchId === fetchIdRef.current) {
          setReports([]);
          reportService
            .getArchivedReports(filterArr, sortStr, pageSize, pageIndex)
            .then((res) => {
              if (mounted.current) {
                setLoading(false);
                setReports(res.data);
                setTotalPages(res.totalPages);
                setTotalCount(res.count);
              }
            })
            .catch((e) => {
              console.log(e);
              setLoading(false);
              alertMethods.error("Something went wrong. Please try again.");
            });
        }
      } else if (fetchId === fetchIdRef.current) {
        // Give this fetch an ID
        // Set the loading state
        setReports([]);
        reportService
          .getReports(filterArr, sortStr, pageSize, pageIndex)
          .then((res) => {
            if (mounted.current) {
              setLoading(false);
              setReports(res.data);
              setTotalPages(res.totalPages);
              setTotalCount(res.count);
            }
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            alertMethods.error("Something went wrong. Please try again.");
          });
      }
    },
    [patientId, type, alertMethods]
  );

  const cellRenderer = useCallback(
    (props, key) => {
      switch (key) {
        case "requestedDate":
          return <DateCell value={props.value} />;
        case "status":
          return <StatusCell value={props.value} />;

        case "reportType":
          return (
            <SubReportCell
              value={props.value}
              row={props.row}
              isMobileWidth={isMobileWidth}
            />
          );
        case "documentType":
          return <FormatCell value={props.value} />;
        case "actions":
          return (
            <ActionsCell
              row={props}
              setDataChange={setDataChange}
              type={type}
              toggleModal={toggleModal}
              setFile={setFile}
              setIsPDF={setIsPDF}
              alertMethods={alertMethods}
              setNumberOfFilters={setNumberOfFilters}
            />
          );
        default:
          return null;
      }
    },
    [alertMethods, isMobileWidth, setDataChange, toggleModal, type]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "requestedDate",
        Cell: (props) => cellRenderer(props, "requestedDate")
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => cellRenderer(props, "status")
      },
      {
        Header: "Report Type",
        accessor: "reportType",
        Cell: (props) => cellRenderer(props, "reportType")
      },
      {
        Header: "Format",
        accessor: "documentType",
        Cell: (props) => cellRenderer(props, "documentType")
      },
      {
        id: "actions",
        disableSortBy: true,
        accessor: (row) => cellRenderer(row, "actions")
      }
    ],
    [cellRenderer]
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <tr>
        <td className="border-0 pt-0 ">
          <div className="text-left text-nowrap">
            <div>Status</div>

            <div>Format</div>
          </div>
        </td>
        <td className="border-0 pt-0 text-nowrap">
          <StatusCell value={row.original.status} row={row} />
          <FormatCell value={row.original.documentType} />
          <div className="d-flex align-items-center">
            <ActionsCell
              row={row.original}
              setDataChange={setDataChange}
              type={type}
              toggleModal={toggleModal}
              setFile={setFile}
              setIsPDF={setIsPDF}
              alertMethods={alertMethods}
              isMobileWidth={true}
            />
          </div>
        </td>
      </tr>
    ),
    [alertMethods, setDataChange, toggleModal, type]
  );

  useEffect(() => {
    if (type !== "archived") {
      setTableHeader(
        <div className="mx-4">
          <span>
            Showing reports from last 7 days. To view older reports, visit the{" "}
          </span>
          <span>
            <Link
              to={
                user?.isSmartSession
                  ? "/smart/reports/archived"
                  : "/reports/archived"
              }
              className=""
              state={{ formerType: type, patientId: patientId }}
            >
              {" "}
              Archived Reports
            </Link>{" "}
          </span>
          <span>section on the Reports tab.</span>
        </div>
      );
    } else {
      setTableHeader(
        <div className="mx-4">
          Viewing Archived Reports from{" "}
          <span>{dayjs(startDate).format("M/D/YY")}</span> -{" "}
          <span>{dayjs(new Date()).format("M/D/YY")}</span>. Reports are deleted
          after 30 days.&nbsp;&nbsp;
          {state?.formerType === "patient" && (
            <Link to="/reports">View newer reports</Link>
          )}
        </div>
      );
    }
  }, [patientId, startDate, state?.formerType, type, user?.isSmartSession]);

  return (
    <div>
      <div id="reports-table">
        <NewGTable
          columns={columns}
          data={reports}
          rowExpands={isMobileWidth}
          emptyColSpan={5}
          fetchData={fetchData}
          pageCount={totalPages}
          totalCount={totalCount}
          loading={loading}
          emptyMsg={<div>You have no Reports</div>}
          noSearch={true}
          headerFilters={visibleHeaderFilters}
          tableHeader={
            user?.isSmartSession && patientId ? undefined : tableHeader
          }
          hideColumnIds={[]}
          mobileHideColumnIds={[
            "documentType",
            // "patientName",
            "actions",
            "status"
          ]}
          renderRowSubComponent={renderRowSubComponent}
          tableState={tableState.reportsTableState}
          updateTableState={updateReportsTableState}
          mobileCarousel={true}
          dataChange={dataChange}
          isSyncActionBtn={true}
          setDataChange={setDataChange}
        />
      </div>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className={classnames(
          "center-modal scroll-modal",
          {
            "modal-xl": !isPDF
          },
          {
            "modal-md": isPDF
          }
        )}
      >
        <ModalHeader
          toggle={toggleModal}
          close={<CloseButton toggle={toggleModal} />}
        >
          Document Viewer
        </ModalHeader>
        <ModalBody
          tabIndex="0"
          className={classnames({ "overflow-x-scroll": !isPDF })}
        >
          {isPDF ? (
            <PdfViewer fileName={file} />
          ) : (
            <ExcelViewer
              byteArray={file}
              numberOfFilters={numberOfFilters + 3}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

ReportsTable.propTypes = {
  type: PropTypes.string,
  patientId: PropTypes.string,
  dataChange: PropTypes.bool,
  setDataChange: PropTypes.func
};

export { ReportsTable };
